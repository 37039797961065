<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขข้อมูลบริหารงานวิจัย</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalYear"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="fiscalYear"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อโครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="projectName"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="projectName"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ผู้วิจัย</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="researcher"
                                rules="required"
                            >
                            <v-textarea
                                outlined
                                dense
                                v-model="researcher"
                                :error-messages="errors"
                            ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>จำนวนเงินทุนวิจัย</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="budget"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="budget"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>แหล่งทุน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="funding"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="funding"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์โครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                v-model="linkProjectFile"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['researchId'],
    data: () => ({
        fiscalYear: "",
        projectName: "",
        budget: 0,
        linkProjectFile: "",
        researcher: "",
        funding: "",
        formChkErrors: false,
    }),
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getData() {
            try {
                const res = (await Api().get('research/' + this.researchId)).data;

                // console.log(res.data)

                if (res.status == 'success') {
                    this.fiscalYear = res.data.fiscal_year;
                    this.projectName = res.data.project_name;
                    this.budget = res.data.budget;
                    this.researcher = res.data.researcher;
                    this.funding = res.data.funding;
                    this.linkProjectFile = res.data.link_project_file;

                } else {
                    console.log(res.data);
                }

            } catch (err) {
                console.log(err.message);
            }
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                fiscal_year: this.fiscalYear,
                project_name: this.projectName,
                budget: this.budget,
                researcher: this.researcher,
                funding: this.funding,
                link_project_file: this.linkProjectFile
            }

            this.formChkErrors = false
            this.formChkErrors = this.$refs.form.validate()

            if(this.formChkErrors){
                let res = (await Api().put("research/" + this.researchId, req)).data;

                // console.log('data', res)

                if (res.status == 'success') {
                    this.$store.commit('setLoading', false)
                    await this.$refs.confirm.open('ข้อความ', 'แก้ไขข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                    this.$router.push('/research')
                }else{
                    this.$store.commit('setLoading', false)
                }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>